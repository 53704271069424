function setRem() {
  const htmlDom = document.getElementsByTagName("html")[0];
  htmlDom.style.fontSize = htmlDom.offsetWidth / 100 + "px";
}

export const pxToRem = (px: number): string => (px * 100) / 1920 + "rem";

export const pxToPx = (px: number): number => {
  const htmlDom = document.getElementsByTagName("html")[0];
  return (htmlDom.offsetWidth * px) / 1920;
};

setRem();
window.onresize = function () {
  setRem();
};
